import { Component } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TranslationService } from './translation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private translationService: TranslationService, private gtmService: GoogleTagManagerService) {
    // initialize the translations
    this.translationService.initialize();
    this.gtmService.addGtmToDom();
  }
}
