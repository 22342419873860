import { Injectable } from '@angular/core';
import { LoggingRepository } from '../repositories/logging.repository';

/**
 * Logging Service
 *
 * This service is used to send logging events to the server
 */
@Injectable()
export class LoggingService {
  /**
   * @param logging Logging repository
   */
  constructor(private logging: LoggingRepository) {}

  /**
   * Sends a logging event to the server
   *
   * @param message Message to send
   * @param gaCategory Google Analytics category
   * @param gaAction Google Analytics action
   * @param gaLabel Google Analytics label
   */
  sendLoggingEvent(message: string, gaCategory: string, gaAction: string, gaLabel: string): void {
    this.logging.sendLoggingEvent(message, gaCategory, gaAction, gaLabel);
  }
}
