import { InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { IBasePage } from '../model/base-page';
import { DefaultPageState } from '../model/default-base-page';
import { DefaultBasePageService } from '../services/default-base-page.service';

/**
 * Base Page Injection Token
 */
export const BASE_PAGE_SERVICE_PROVIDER = new InjectionToken<IBasePage<DefaultPageState>>('base-page');

export const defaultBasePageServiceFactory = (router: Router): IBasePage<DefaultPageState> => new DefaultBasePageService(router);
