import { InjectionToken } from '@angular/core';

/**
 * Localization config to run the localization
 */
export interface LocalizationConfig {

  /**
   * Defines the default locale for the translations
   */
  defaultLocale: string;

  /**
   * Defines all available locales for the translations
   */
  locales: string[];
}

/**
 * Localization config injection token
 */
export const LOCALIZATION_CONFIG = new InjectionToken<LocalizationConfig>('localization_config');
