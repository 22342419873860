import { Component, OnInit } from '@angular/core';
import { IAMService } from 'app/auth';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit {
  // claims
  private allowToSeeOrderHistoryClaim = 'SPS_AllowToSeeOrderHistory';
  private allowToSeeOrderQuoteClaim = 'SPS_AllowToSeeQuoteHistory';

  // template variable
  public allowToSeeOrderHistory: Observable<boolean>;
  public allowToSeeQuoteHistory: Observable<boolean>;

  constructor(private iamService: IAMService) {}

  public ngOnInit(): void {
    this.allowToSeeOrderHistory = this.iamService.ensureHasClaim(this.allowToSeeOrderHistoryClaim);
    this.allowToSeeQuoteHistory = this.iamService.ensureHasClaim(this.allowToSeeOrderQuoteClaim);
  }
}
