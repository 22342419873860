<div fxLayout="row" fxLayoutAlign="start center">
  <app-search></app-search>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <app-cart-status></app-cart-status>
    <div class="profile-image-top-bar">
      <img class="defaultimage" src="assets/img/account_circle-24px.svg" alt="Seepex User Icon" />
    </div>
    <mat-menu #appMenu="matMenu">
      <div [disabled]="true" fxHide="true" fxShow.lt-sm="true" mat-menu-item>{{ (identity | async)?.Identifier }}</div>
      <button *ngIf="allowedToChangeUserPassword | async" type="button" mat-menu-item (click)="changePassword()">
        <mat-icon>account_circle</mat-icon>{{ 'CHNG_PASS' | translate }}
      </button>
      <button type="button" mat-menu-item (click)="logout()"><mat-icon>power_settings_new</mat-icon>{{ 'LOGOUT' | translate }}</button>
    </mat-menu>
    <p class="email-top-bar" fxShow="true" fxHide.lt-sm="true">
      {{ (identity | async)?.Identifier }}
    </p>
    <spx-icon-button [type]="'mat-icon-button'" [iconName]="'keyboard_arrow_down'" [matMenuTriggerFor]="appMenu"></spx-icon-button>
  </div>
</div>
