import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PageStateActionService } from 'app/store/actions/page-state.action.service';
import { PageState } from 'app/store/models/page-state';
import { AppStateSelectorService } from 'app/store/selectors/app-state-selector.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IBasePage } from '../../shared/modules/base-page/model/base-page';

@Injectable()
export class BasePageService implements IBasePage<PageState> {
  private currentUrl: string;

  constructor(private pageStateActionService: PageStateActionService, private appStateSelectorService: AppStateSelectorService, private router: Router) {
    this.router.events.pipe(filter((routeEvent) => routeEvent instanceof NavigationEnd)).subscribe((routeEvent: NavigationEnd) => {
      this.currentUrl = routeEvent.url;
    });
  }

  setPageState(pageState: PageState): void {
    pageState.route = this.currentUrl;
    this.pageStateActionService.setPageState(pageState);
  }

  getPageState(): Observable<PageState> {
    return this.appStateSelectorService.getPageState(this.currentUrl);
  }

  getDefaultValue(): PageState {
    return {
      route: '',
      virtualScrollState: {
        dataOffset: 0,
        scrollIndex: 0,
      },
    };
  }
}
