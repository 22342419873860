import { CurrentCartItemPrice } from 'app/features/cart';
import { Async } from 'app/store';
import * as clonedeep from 'lodash.clonedeep';
import { CartItemPriceActions, CartItemPriceActionTypes } from '../actions/cart-item-price.actions';
import { CartItemActions, CartItemActionTypes } from '../actions/cart-item.actions';
/**
 * Cart Item Price Reducer implementation
 *
 * @param prevState Previous Store state for cart item prices
 * @param action Cart Item Price or Cart Item Actions
 * @returns Cart Item Price array in async object of the current store state
 */
export function cartItemPriceReducer(prevState: Async<CurrentCartItemPrice>[], action: CartItemPriceActions | CartItemActions): Async<CurrentCartItemPrice>[] {
  let clonedState: Async<CurrentCartItemPrice>[];
  let existing: Async<CurrentCartItemPrice, any>;

  switch (action.type) {
    case CartItemPriceActionTypes.GetCartItemPriceRequest:
      clonedState = clonedeep(prevState);
      existing = clonedState.find((cartItemPrice) => cartItemPrice.data.Id === action.payload.id);
      if (existing) {
        existing.error = undefined;
        existing.isPending = true;
      }

      return clonedState;
    case CartItemPriceActionTypes.GetCartItemPriceSuccess:
      clonedState = clonedeep(prevState);
      existing = clonedState.find((cartItemPrice) => cartItemPrice.data.Id === action.payload.id);
      if (existing) {
        existing.data = action.payload.cartItemPrice;
        existing.error = undefined;
        existing.isPending = false;
      } else {
        clonedState.push({
          data: action.payload.cartItemPrice,
          isPending: false,
        });
      }

      return clonedState;
    case CartItemPriceActionTypes.GetCartItemPriceFailure:
      clonedState = clonedeep(prevState);
      existing = clonedState.find((cartItemPrice) => cartItemPrice.data.Id === action.payload.id);
      if (existing) {
        existing.error = action.payload.error;
        existing.isPending = false;
      }

      return clonedState;
    case CartItemActionTypes.DeleteCartItemSuccess:
      clonedState = clonedeep(prevState);
      clonedState = clonedState.filter((d) => d.data.Id !== action.payload.id);

      return clonedState;

    case CartItemActionTypes.ResetCartItemStateAction:
      clonedState = [];

      return clonedState;
    default:
      return prevState;
  }
}
