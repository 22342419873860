import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import cssVars from 'css-vars-ponyfill';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://efc73af7e4f44ecba6256b62476f956d@o1002266.ingest.sentry.io/4504044019318784',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['https://beta-spareparts.seepex.com', 'https://spareparts.seepex.com'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,
  environment: environment.name,
  beforeSend(event) {
    // don't send invalid nonce in state errors to Sentry, because this is a normal behaviour, when a session expires
    if (event.transaction === '/oauth2callback/' && event.exception.values.some((exc) => exc.value?.includes('invalid_nonce_in_state'))) {
      return null;
    }

    // don't send error states, which are coming from other origins
    if (!event.request.url.startsWith(window.location.origin)) {
      return null;
    }

    return event;
  },
});

// replace css variables for internet explorer
cssVars({
  watch: true,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
