import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as clonedeep from 'lodash.clonedeep';
import { AppState } from '../AppState';

/**
 * AppStateCollectionService
 *
 * Service which contains all defined selectors for the app state
 */
@Injectable({
  providedIn: 'root',
})
export class AppStateCollectionService {
  // Feature collection selector (must be same name as feature collection name)
  private getAppState = createFeatureSelector<AppState>('appState');

  /**
   * Selects the page state by route url
   */
  public selectPageState = (route: string) =>
    createSelector(this.getAppState, (appState: AppState) => clonedeep(appState.pageStates.find((pS) => pS.route === route)));
}
