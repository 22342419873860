import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { SpxOAuthService } from '@seepex/ng-oauth';
import { WINDOW } from 'app/providers/window';
import { Observable } from 'rxjs';
import { OciLogin } from '../models';

@Injectable()
export class SingleSignOnLoginGuard implements CanActivate, CanActivateChild {
  /**
   * OCI Login Parameter Name for User
   */
  private static readonly OCI_USERNAME_PARAMETER_NAME: string = 'username';

  /**
   * OCI Login Parameter Name for Password
   */
  private static readonly OCI_PASSWORD_PARAMETER_NAME: string = 'password';

  constructor(@Inject(WINDOW) private window: Window, private authService: SpxOAuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    // return true, if we have a valid user
    const valid = this.authService.hasValidAccessToken();

    if (valid) {
      return true;
    }

    // get oci login params from url params
    const ociLoginParams = this.getOciLoginParams();

    // set oci state without username and password
    const ociState = state.url
      .replace(`${SingleSignOnLoginGuard.OCI_USERNAME_PARAMETER_NAME}=${ociLoginParams.ociUsername}`, '')
      .replace(`${SingleSignOnLoginGuard.OCI_PASSWORD_PARAMETER_NAME}=${ociLoginParams.ociPassword}`, '');

    if (ociLoginParams.ociUsername && ociLoginParams.ociPassword) {
      this.authService.logIn(ociState, ociLoginParams);
    } else {
      this.router.navigate(['']);
    }

    return false;
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.canActivate(route, state);
  }

  /**
   * Returns the OCI Params, which are necessary for the Login
   *
   * @returns OciLogin Params
   */
  private getOciLoginParams(): OciLogin {
    const params = new URLSearchParams(this.window.location.search);
    const ociUsername = params.get(SingleSignOnLoginGuard.OCI_USERNAME_PARAMETER_NAME) ?? '';
    const ociPassword = params.get(SingleSignOnLoginGuard.OCI_PASSWORD_PARAMETER_NAME) ?? '';

    return {
      ociUsername,
      ociPassword,
    };
  }
}
