import { Action } from '@ngrx/store';
import { CartItem } from '../../models';

/**
 * Cart Item Action Types
 */
export enum CartItemActionTypes {
  GetCartItemsRequest = '[CartItem] Get Cart Items Request',
  GetCartItemsFailure = '[CartItem] Get Cart Items Failure',
  GetCartItemsSuccess = '[CartItem] Get Cart Items Success',
  DeleteCartItemRequest = '[CartItem] Delete Cart Item Request',
  DeleteCartItemFailure = '[CartItem] Delete Cart Item Failure',
  DeleteCartItemSuccess = '[CartItem] Delete Cart Item Success',
  ChangeQuantityForCartItemRequest = '[CartItem] Change quantity for Cart Item Request',
  ChangeQuantityForCartItemFailure = '[CartItem] Change quantity for Cart Item Failure',
  ChangeQuantityForCartItemSuccess = '[CartItem] Change quantity for Cart Item Success',
  ResetCartItemStateAction = '[CartItem] Reset cart item state',
}

/**
 * Get cart items request action definition
 */
export class GetCartItemsRequestAction implements Action {
  readonly type = CartItemActionTypes.GetCartItemsRequest;
}

/**
 * Get cart items failure action definition
 */
export class GetCartItemsFailureAction implements Action {
  readonly type = CartItemActionTypes.GetCartItemsFailure;

  constructor(readonly payload: { error: any }) {}
}

/**
 * Get cart items success action definition
 */
export class GetCartItemsSuccessAction implements Action {
  readonly type = CartItemActionTypes.GetCartItemsSuccess;

  constructor(readonly payload: { cartItems: CartItem[] }) {}
}

/**
 * Delete cart item request action definition
 */
export class DeleteCartItemRequestAction implements Action {
  readonly type = CartItemActionTypes.DeleteCartItemRequest;

  constructor(readonly payload: { id: number }) {}
}

/**
 * Delete cart item failure action definition
 */
export class DeleteCartItemFailureAction implements Action {
  readonly type = CartItemActionTypes.DeleteCartItemFailure;

  constructor(readonly payload: { id: number; error: any }) {}
}

/**
 * Delete cart item success action definition
 */
export class DeleteCartItemSuccessAction implements Action {
  readonly type = CartItemActionTypes.DeleteCartItemSuccess;

  constructor(readonly payload: { id: number }) {}
}

/**
 * Change quantity for cart item request action definition
 */
export class ChangeQuantityForCartItemRequestAction implements Action {
  readonly type = CartItemActionTypes.ChangeQuantityForCartItemRequest;

  constructor(readonly payload: { id: number; quantity: number }) {}
}

/**
 * Change quantity for cart item failure action definition
 */
export class ChangeQuantityForCartItemFailureAction implements Action {
  readonly type = CartItemActionTypes.ChangeQuantityForCartItemFailure;

  constructor(readonly payload: { id: number; error: any }) {}
}

/**
 * Change quantity for cart item success action definition
 */
export class ChangeQuantityForCartItemSuccessAction implements Action {
  readonly type = CartItemActionTypes.ChangeQuantityForCartItemSuccess;

  constructor(readonly payload: { id: number; quantity: number }) {}
}

/**
 * Resets the cart items
 */
export class ResetCartItemAction implements Action {
  readonly type = CartItemActionTypes.ResetCartItemStateAction;
}

/**
 * Cart Item Actions
 */
export type CartItemActions =
  | GetCartItemsRequestAction
  | GetCartItemsFailureAction
  | GetCartItemsSuccessAction
  | DeleteCartItemRequestAction
  | DeleteCartItemFailureAction
  | DeleteCartItemSuccessAction
  | ChangeQuantityForCartItemRequestAction
  | ChangeQuantityForCartItemFailureAction
  | ChangeQuantityForCartItemSuccessAction
  | ResetCartItemAction;
