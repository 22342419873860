import { Injectable } from '@angular/core';
import { SpxOAuthService } from '@seepex/ng-oauth';
import { OAuthHttpService } from 'app/auth';
import { environment } from 'environment';

/**
 * Logging Repsitory
 *
 * Http implementation of sending log events to the server
 */
@Injectable({
  providedIn: 'root',
})
export class LoggingRepository {
  /**
   * @param http Authenticated Http Service
   */
  constructor(private oAuthService: SpxOAuthService, private oAuthHttpService: OAuthHttpService) {}

  /**
   * Http implementation to send logs to the server
   *
   * @param message Message to send
   * @param gaCategory Google Analytics category
   * @param gaAction Google Analytics action
   * @param gaLabel Google Analytics label
   */
  sendLoggingEvent(message: string, gaCategory: string, gaAction: string, gaLabel: string): void {
    const body: unknown = {
      Message: message,
      CreatedOn: new Date().toISOString(),
      Source: 'Shop Frontend',
    };
    if (gaCategory !== 'navigate' && (window as any).ga) {
      (window as any).ga('send', 'event', gaCategory, gaAction, gaLabel);
    }

    if (this.oAuthService.hasValidAccessToken()) {
      this.oAuthHttpService.post(`${environment.endpoint}/events`, body).toPromise();
    }
  }
}
