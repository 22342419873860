import { Router } from '@angular/router';
import { PageStateActionService } from 'app/store/actions/page-state.action.service';
import { PageState } from 'app/store/models/page-state';
import { AppStateSelectorService } from 'app/store/selectors/app-state-selector.service';
import { IBasePage } from '../../shared/modules/base-page/model/base-page';
import { BasePageService } from '../services/base-page.service';

/**
 * Factory default config
 *
 * @returns Configuration of table virtual scrolling
 */
export const basePageServiceFactory = (pageStateActionService: PageStateActionService, appStateSelectorService: AppStateSelectorService, router: Router): IBasePage<PageState> => new BasePageService(pageStateActionService, appStateSelectorService, router);
