import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IIdentity } from '../models/';
import { IAMHttpRepository } from '../repositories';

@Injectable()
export class IAMService {
  constructor(private iamHttpRepository: IAMHttpRepository) {}

  public getIdentity(): Observable<IIdentity> {
    return this.iamHttpRepository.getIdentity();
  }

  public ensureHasClaim(claim: string, claimValue?: string): Observable<boolean> {
    return this.iamHttpRepository.ensureHasClaim(claim, claimValue).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getSalesOrganizationId(): Observable<string> {
    return this.iamHttpRepository.getSalesOrganizationId();
  }
}
