import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CartItem, CurrentCartItemAvailability, CurrentCartItemPrice } from '../models';
import { CarttHttpRepository } from '../repositories';

/**
 * Cart Current Service
 *
 * This service can be used to change data on the server for the current cart
 */
@Injectable()
export class CartService {
  /**
   * @param cartCurrentRepository Cart current repository with http implementation of the current cart
   */
  constructor(private cartCurrentRepository: CarttHttpRepository) {}

  /**
   * Get the cart items
   *
   * @returns List of cart items
   */
  public getCurrentCartItems(): Observable<Array<CartItem>> {
    return this.cartCurrentRepository.getCurrentCartItems();
  }

  /**
   * Get the price of the cart item by id
   *
   * @param id Cart item id
   * @param customerNumber Customer number
   * @param salesOrganizationId Sales organization id
   * @returns Price of cart item
   */
  public getPrice(id: number, customerNumber: string, salesOrganizationId: string): Observable<CurrentCartItemPrice> {
    return this.cartCurrentRepository.getPrice(id, customerNumber, salesOrganizationId);
  }

  /**
   * Changes the quantity of a cart item
   *
   * @param id Cart item id
   * @param quantity New quantity
   * @returns Change http response
   */
  public changeQuantityForItems(id: number, quantity: number): Observable<any> {
    return this.cartCurrentRepository.changeQuantityForItem(id, quantity);
  }

  /**
   * Get the list of availabilities for the current cart
   *
   * @param salesOrganizationId Sales organization id
   * @returns List of cart item availabilities
   */
  public getAvailabilities(salesOrganizationId: string): Observable<Array<CurrentCartItemAvailability>> {
    return this.cartCurrentRepository.getAvailabilities(salesOrganizationId);
  }

  /**
   * Deletes a cart item from the current cart
   *
   * @param id Cart item id
   * @returns Deletion http response
   */
  public removeItem(id: number): Observable<any> {
    return this.cartCurrentRepository.removeItem(id).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  /**
   * Removes all cart items from the current cart
   *
   * @returns Success observable
   */
  public removeAllCartItems(): Observable<boolean> {
    return this.cartCurrentRepository.removeAllCartItems().pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  /**
   * Adds cart items to the current cart
   *
   * @param items Cart Items
   * @returns Success stream
   */
  public addCartItemsToCart(items: Array<CartItem>): Observable<boolean> {
    return this.cartCurrentRepository.addCartItemsToCart(items);
  }
}
