import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthHttpService } from 'app/auth';
import { environment } from 'environment';
import { Observable } from 'rxjs';
import { Search, SearchOrderKey } from '../models/search';

@Injectable({
  providedIn: 'root',
})
export class SearchHttpRepository {
  constructor(private http: OAuthHttpService) {}

  /**
   * Gets the search result from the server
   *
   * @param searchString search string
   * @param searchType search type
   * @returns Search object, which contains the type of search result (device, order, ...)
   */
  public search(searchString: string, searchType: string): Observable<Search> {
    let params: HttpParams = new HttpParams();
    params = params.append('text', searchString);
    params = params.append('searchType', searchType);

    return this.http.get<Search>(`${environment.endpoint}/search/eval`, { params });
  }

  /**
   * Gets the order key for a order
   *
   * @param orderNumber Order number
   * @returns Order key object
   */
  public getOrderHirstoryKeys(orderNumber: string): Observable<SearchOrderKey> {
    return this.http.get<SearchOrderKey>(`${environment.endpoint}/orderhistory/keys/` + orderNumber);
  }
}
