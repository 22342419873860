import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Loading Service
 *
 * This service can be used to control the loading state within the application
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  // emitter subject
  private loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Emits each time the loading was started or stopped
   */
  public isLoading: Observable<boolean> = this.loading$.asObservable();

  /**
   * Start loading
   */
  public startLoading(): void {
    setTimeout(() => this.loading$.next(true));
  }

  /**
   * Stop loading
   */
  public stopLoading(): void {
    setTimeout(() => this.loading$.next(false));
  }
}
