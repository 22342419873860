import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CartService } from '../../services';
import { CartItemPriceActionTypes, GetCartItemPriceRequestAction } from '../actions/cart-item-price.actions';

/**
 * CartItemPriceEffect
 *
 * Ngrx Effects for the price of a cart item
 */
@Injectable({
  providedIn: 'root',
})
export class CartItemPriceEffect {
  /**
   * @param actions$ Ngrx Actions
   * @param cartCurrentService Cart current service
   */
  constructor(private actions$: Actions, private cartCurrentService: CartService) {}

  /**
   * Get cart item price effect
   */
  cartItemPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCartItemPriceRequestAction>(CartItemPriceActionTypes.GetCartItemPriceRequest),
      mergeMap((action) =>
        this.cartCurrentService.getPrice(action.payload.id, action.payload.customerNumber, action.payload.salesOrganizationId).pipe(
          map((cartItemPrice) => ({ type: CartItemPriceActionTypes.GetCartItemPriceSuccess, payload: { id: action.payload.id, cartItemPrice } })),
          catchError((error) => of({ type: CartItemPriceActionTypes.GetCartItemPriceFailure, payload: { id: action.payload.id, error } }))
        )
      )
    )
  );
}
