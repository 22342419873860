import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CartService } from '../../services';
import { CartItemActionTypes, ChangeQuantityForCartItemRequestAction, DeleteCartItemRequestAction } from '../actions/cart-item.actions';

/**
 * CartItemsEffect
 *
 * Ngrx Effects for for cart items
 */
@Injectable({
  providedIn: 'root',
})
export class CartItemsEffect {
  /**
   * @param actions$ Ngrx Actions
   * @param cartCurrentService Cart current service
   */
  constructor(private actions$: Actions, private cartCurrentService: CartService) {}

  /**
   * Get cart items effect
   */
  cartItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartItemActionTypes.GetCartItemsRequest),
      mergeMap(() =>
        this.cartCurrentService.getCurrentCartItems().pipe(
          map((cartItems) => ({ type: CartItemActionTypes.GetCartItemsSuccess, payload: { cartItems } })),
          catchError((error) => of({ type: CartItemActionTypes.GetCartItemsFailure, payload: { error } }))
        )
      )
    )
  );

  /**
   * Delete cart item effect
   */
  cartItemDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCartItemRequestAction>(CartItemActionTypes.DeleteCartItemRequest),
      mergeMap((action) =>
        this.cartCurrentService.removeItem(action.payload.id).pipe(
          map(() => ({ type: CartItemActionTypes.DeleteCartItemSuccess, payload: { id: action.payload.id } })),
          catchError((error) => of({ type: CartItemActionTypes.DeleteCartItemFailure, payload: { id: action.payload.id, error } }))
        )
      )
    )
  );

  /**
   * Change quantity for cart item effect
   */
  cartItemchangeQuantity$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeQuantityForCartItemRequestAction>(CartItemActionTypes.ChangeQuantityForCartItemRequest),
      mergeMap((action) =>
        this.cartCurrentService.changeQuantityForItems(action.payload.id, action.payload.quantity).pipe(
          map(() => ({ type: CartItemActionTypes.ChangeQuantityForCartItemSuccess, payload: { id: action.payload.id, quantity: action.payload.quantity } })),
          catchError((error) => of({ type: CartItemActionTypes.ChangeQuantityForCartItemFailure, payload: { id: action.payload.id, error } }))
        )
      )
    )
  );
}
