/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'app/features/cart';
import { WINDOW } from 'app/providers/window';
import { OCIParameters } from '../../models';
import { OCIParametersService } from '../../services';

@Component({
  selector: 'app-oci-parameters',
  templateUrl: './oci-parameters.component.html',
})
export class OCIParametersComponent implements OnInit {
  // Template variables
  public missingMandatoryParameters = false;

  /**
   * OCI Hook Url Parameter Name
   */
  private readonly HOOKURL_PARAMETER_NAME: string = 'HOOK_URL';

  /**
   * OCI Hook Url Parameter Name
   */
  private readonly RETURN_TARGET_PARAMETER_NAME: string = 'return_target';

  /**
   * OCI Hook Url Parameter Name
   */
  private readonly VENDOR_NUMBER_PARAMETER_NAME: string = 'vendor_number';

  /**
   * OCI Hook Url Parameter Name
   */
  private readonly PRODUCT_GROUP_PARAMETER_NAME: string = 'product_group';

  constructor(@Inject(WINDOW) private window: Window, private router: Router, private ociParametersService: OCIParametersService, private cartCurrentService: CartService) {}

  public ngOnInit(): void {
    // get the search parameters
    const params = new URLSearchParams(this.window.location.search);

    const ociParameters: OCIParameters = {
      HookUrl: params.get(this.HOOKURL_PARAMETER_NAME),
      ProductGroup: params.get(this.PRODUCT_GROUP_PARAMETER_NAME),
      Return_Target: params.get(this.RETURN_TARGET_PARAMETER_NAME) ?? '_parent',
      VendorNumber: params.get(this.VENDOR_NUMBER_PARAMETER_NAME),
    };

    // save the oci parameters
    this.ociParametersService.saveOCIParameters(ociParameters).subscribe({
      next: () => {
        this.cartCurrentService.removeAllCartItems().subscribe(() => {
          this.router.navigate(['home']).then(() => this.window.location.reload());
        });
      },
      error: () => {
        this.cartCurrentService.removeAllCartItems().subscribe(() => {
          this.missingMandatoryParameters = true;
        });
      },
    });
  }
}
