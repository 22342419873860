import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CartItemPipe } from './pipes/cart-item.pipe';
import { CarttHttpRepository } from './repositories';
import { CartService } from './services/cart-current.service';
import { CartItemAvailybilityEffect } from './store/effects/cart-item-availability.effect';
import { CartItemPriceEffect } from './store/effects/cart-item-price.effect';
import { CartItemsEffect } from './store/effects/cart-items.effect';
import { cartItemsReducers } from './store/reducers/reducer';

@NgModule({
  declarations: [CartItemPipe],
  imports: [StoreModule.forFeature('cartItems', cartItemsReducers), EffectsModule.forFeature([CartItemsEffect, CartItemPriceEffect, CartItemAvailybilityEffect])],
  providers: [CarttHttpRepository, CartService],
  exports: [CartItemPipe],
})
export class CartModule {}
