import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PageState } from '../models/page-state';
import { SetPageStateAction } from './page-state.actions';

@Injectable({
  providedIn: 'root',
})
export class PageStateActionService {
  /**
   * @param store Ngrx store instance
   */
  constructor(private store: Store) {}

  /**
   * Set page state
   */
  public setPageState(pageState: PageState): void {
    this.store.dispatch(new SetPageStateAction({ pageState }));
  }
}
