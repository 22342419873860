import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { environment } from 'environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAMService } from '../services';

@Injectable()
export class NoAccessGuard implements CanActivate, CanActivateChild {
  constructor(private iamService: IAMService, private router: Router) {}

  public canActivate(): Observable<boolean> {
    return this.iamService.ensureHasClaim('ApplicationLogin', environment.applicationLoginClaimValue).pipe(
      map((hasClaim) => {
        if (!hasClaim) {
          this.router.navigate(['noaccess']);
        }
        return hasClaim;
      })
    );
  }

  public canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
