import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartItem, CurrentCartItemAvailability, CurrentCartItemPrice } from 'app/features/cart';
import { Async } from 'app/store';
import { Observable } from 'rxjs';
import { CartItemCollectionService } from './cart-item-collection.service';
/**
 * CartItemSelectorService
 *
 * Selects the cart item data from the store with defined selectors
 */
@Injectable({
  providedIn: 'root',
})
export class CartItemSelectorService {
  /**
   * @param store Ngrx store instance
   * @param cartItemCollectionService Cart items collection service
   */
  constructor(private store: Store, private cartItemCollectionService: CartItemCollectionService) {}

  /**
   * Get the cart item by material number and position from the store
   *
   * @param materialNumber sparepart material number
   * @param position position of cart item
   * @returns cart item stream
   */
  public getCartItem(materialNumber: string, position: string, pumpMaterialNumber: string): Observable<CartItem> {
    return this.store.select(this.cartItemCollectionService.selectCartItem(materialNumber, position, pumpMaterialNumber));
  }

  /**
   * Get the cart items from the store
   *
   * @returns Async object with cart item array
   */
  public getCartItems(): Observable<Async<CartItem[]>> {
    return this.store.select(this.cartItemCollectionService.selectCartItems);
  }

  /**
   * Get the cart items count from the store
   *
   * @returns Observable of cart items count
   */
  public getCartItemsCount(): Observable<number> {
    return this.store.select(this.cartItemCollectionService.selectCartItemsCount);
  }

  /**
   * Get the cart item prices array from the store
   *
   * @returns Cart item prices array
   */
  public getTotalCartPrice(): Observable<Async<CurrentCartItemPrice>[]> {
    return this.store.select(this.cartItemCollectionService.selectCartPrices);
  }

  /**
   * Get the price by cart item id from the store
   *
   * @param id Cart item ID
   * @returns Async object with current cart item price object
   */
  public getCartItemPrice(id: number): Observable<Async<CurrentCartItemPrice>> {
    return this.store.select(this.cartItemCollectionService.selectCartItemPrice(id));
  }

  /**
   * Get the availabilities of the current cart items
   *
   * @returns Async object with cart item availabilities
   */
  public getAvailabilities(): Observable<Async<CurrentCartItemAvailability[]>> {
    return this.store.select(this.cartItemCollectionService.selectCartItemAvailabilities);
  }
}
