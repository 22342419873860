import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModule } from '@seepex/ng-base';
import { SpxOAuthModule } from '@seepex/ng-oauth';
import { OCIParametersComponent } from './components';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { OAuthCallbackComponent } from './components/oauthcallback/oauthcallback.component';
import { NoAccessGuard, SingleSignOnLoginGuard } from './guards';
import { AUTH_CONFIG, AUTH_CONFIG_DEFAULT } from './providers/auth.config';
import { IAMHttpRepository } from './repositories';
import { IAMService, OAuthHttpService, OCIParametersService } from './services';

@NgModule({
  imports: [
    CommonModule,
    BaseModule,
    TranslateModule.forChild(),
    SpxOAuthModule.forRoot({
      issuer: AUTH_CONFIG_DEFAULT.authorizationUri,
      redirectUri: AUTH_CONFIG_DEFAULT.redirectUri,
      clientId: AUTH_CONFIG_DEFAULT.clientId,
      scope: AUTH_CONFIG_DEFAULT.scopes,
      responseType: AUTH_CONFIG_DEFAULT.responseTypes,
    }),
  ],
  providers: [
    OAuthHttpService,
    NoAccessGuard,
    SingleSignOnLoginGuard,
    IAMHttpRepository,
    OCIParametersService,
    IAMService,
    {
      provide: AUTH_CONFIG,
      useValue: AUTH_CONFIG_DEFAULT,
    },
  ],
  declarations: [OAuthCallbackComponent, NoAccessComponent, OCIParametersComponent],
})
export class OAuthModule {}
