import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetCartItemAvailablityRequestAction } from './cart-item-availability.actions';
import { GetCartItemPriceRequestAction } from './cart-item-price.actions';
import { ChangeQuantityForCartItemRequestAction, DeleteCartItemRequestAction, GetCartItemsRequestAction, ResetCartItemAction } from './cart-item.actions';

@Injectable({
  providedIn: 'root',
})
export class CartItemActionService {
  /**
   * @param store Ngrx store instance
   */
  constructor(private store: Store) {}

  /**
   * Request cart items
   */
  public getCartItems(): void {
    this.store.dispatch(new GetCartItemsRequestAction());
  }

  /**
   * Request price for cart item
   *
   * @param id Cart item ID
   * @param customerNumber Customer number
   * @param salesOrganizationId Sales organization ID
   */
  public getCartItemPrice(id: number, customerNumber: string, salesOrganizationId: string): void {
    this.store.dispatch(new GetCartItemPriceRequestAction({ id, customerNumber, salesOrganizationId }));
  }

  /**
   *  Request delete for cart item
   *
   * @param id cart item ID
   */
  public deleteCartItem(id: number) {
    this.store.dispatch(new DeleteCartItemRequestAction({ id }));
  }

  /**
   * Request quantity change of cart item
   *
   * @param id cart item ID
   * @param quantity quantity to change
   */
  public changeQuantityForCartItem(id: number, quantity: number) {
    this.store.dispatch(new ChangeQuantityForCartItemRequestAction({ id, quantity }));
  }

  /**
   * Request availabilities
   *
   * @param salesOrganizationId sales organization ID
   */
  public getAvailabilities(salesOrganizationId: string) {
    this.store.dispatch(new GetCartItemAvailablityRequestAction({ salesOrganizationId }));
  }

  /**
   * Resets the cart items state
   */
  public clearCart(): void {
    this.store.dispatch(new ResetCartItemAction());
  }
}
