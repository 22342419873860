import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModule, MenuModule, SidenavModule } from '@seepex/ng-base';
import { CartModule } from 'app/features/cart';
import { CartStatusComponent } from './components/cart-status/cart-status.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SearchComponent } from './components/search/search.component';
import { UserSnapComponent } from './components/user-snap/user-snap.component';

@NgModule({
  declarations: [LayoutComponent, HeaderComponent, NavigationComponent, CartStatusComponent, SearchComponent, UserSnapComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatInputModule,
    MatDividerModule,
    MatFormFieldModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MenuModule,
    SidenavModule,
    BaseModule,
    CartModule,
    RouterModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
