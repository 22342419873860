import { Async } from 'app/store';
import * as clonedeep from 'lodash.clonedeep';
import { CartItem } from '../../models';
import { CartItemActions, CartItemActionTypes } from '../actions/cart-item.actions';
/**
 * Initial Cart Items State
 */
export const intitialCartState: Async<CartItem[]> = {
  data: null,
  isPending: false,
};

/**
 * Cart Items Reducer implementation
 *
 * @param prevState Previous Store state for cart items
 * @param action Cart Items Action
 * @returns Cart Items array in async object of the current store state
 */
export function cartItemsReducer(prevState: Async<CartItem[]>, action: CartItemActions): Async<CartItem[]> {
  let clonedState: Async<CartItem[]>;

  switch (action.type) {
    case CartItemActionTypes.GetCartItemsRequest:
    case CartItemActionTypes.DeleteCartItemRequest:
    case CartItemActionTypes.ChangeQuantityForCartItemRequest:
      clonedState = clonedeep(prevState);
      clonedState.error = undefined;
      clonedState.isPending = true;
      return clonedState;
    case CartItemActionTypes.GetCartItemsFailure:
    case CartItemActionTypes.DeleteCartItemFailure:
    case CartItemActionTypes.ChangeQuantityForCartItemFailure:
      clonedState = clonedeep(prevState);
      clonedState.error = action.payload.error;
      clonedState.isPending = false;
      return clonedState;
    case CartItemActionTypes.GetCartItemsSuccess:
      clonedState = clonedeep(prevState);
      clonedState.error = undefined;
      clonedState.isPending = false;
      clonedState.data = action.payload.cartItems;
      return clonedState;
    case CartItemActionTypes.DeleteCartItemSuccess:
      clonedState = clonedeep(prevState);
      clonedState.error = undefined;
      clonedState.isPending = false;
      clonedState.data = clonedState.data.filter((d) => d.Id !== action.payload.id);
      return clonedState;
    case CartItemActionTypes.ChangeQuantityForCartItemSuccess:
      clonedState = clonedeep(prevState);
      clonedState.error = undefined;
      clonedState.isPending = false;

      // set new quantity
      const existing = clonedState.data.find((cartItem) => cartItem.Id === action.payload.id);
      if (existing) {
        existing.Quantity = action.payload.quantity;
      }

      return clonedState;

    case CartItemActionTypes.ResetCartItemStateAction:
      clonedState = clonedeep(prevState);
      clonedState.data = [];
      return clonedState;
    default:
      return prevState;
  }
}
