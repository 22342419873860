import { Action } from '@ngrx/store';
import { CurrentCartItemAvailability } from '../../models';

/**
 * Cart Item Availability Action Types
 */
export enum CartItemAvailablityActionTypes {
  GetCartItemAvailablityRequest = '[CartItem] Get Cart Item Availabilites Request',
  GetCartItemAvailablityFailure = '[CartItem] Get Cart Item Availabilites Failure',
  GetCartItemAvailablitySuccess = '[CartItem] Get Cart Item Availabilites Success',
}

/**
 * Get cart item availability request action definition
 */
export class GetCartItemAvailablityRequestAction implements Action {
  readonly type = CartItemAvailablityActionTypes.GetCartItemAvailablityRequest;

  constructor(readonly payload: { salesOrganizationId: string }) {}
}

/**
 * Get cart item availability failure action definition
 */
export class GetCartItemAvailablityFailureAction implements Action {
  readonly type = CartItemAvailablityActionTypes.GetCartItemAvailablityFailure;

  constructor(readonly payload: { error: any }) {}
}

/**
 * Get cart item availability success action definition
 */
export class GetCartItemAvailablitySuccessAction implements Action {
  readonly type = CartItemAvailablityActionTypes.GetCartItemAvailablitySuccess;

  constructor(readonly payload: { availabilities: CurrentCartItemAvailability[] }) {}
}

/**
 * Cart Item Availability Actions
 */
export type CartItemAvailablityActions = GetCartItemAvailablityRequestAction | GetCartItemAvailablityFailureAction | GetCartItemAvailablitySuccessAction;
