import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeTh from '@angular/common/locales/th';
import localeVi from '@angular/common/locales/vi';
import { NgModule } from '@angular/core';
import { environment } from 'environment';
import { localeProvider, LOCALIZATION_CONFIG } from './providers';
import { TranslationService } from './services';

// Register necessary Angular locales
registerLocaleData(localeEn);
registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeJa);
registerLocaleData(localeVi);
registerLocaleData(localeKo);
registerLocaleData(localeTh);

@NgModule({
  imports: [],
  providers: [
    TranslationService,
    {
      provide: LOCALIZATION_CONFIG,
      useValue: {
        defaultLocale: environment.fallbackLanguage,
        locales: environment.supportedLanguages,
      },
    },
    localeProvider,
  ],
})
export class TranslationModule {}
