import { CurrentCartItemAvailability } from 'app/features/cart';
import { Async } from 'app/store';
import * as clonedeep from 'lodash.clonedeep';
import { CartItemAvailablityActions, CartItemAvailablityActionTypes } from '../actions/cart-item-availability.actions';
import { CartItemActions, CartItemActionTypes } from '../actions/cart-item.actions';
/**
 * Initial state for cart item availabilities
 */
export const intitialCartItemAvailybilitiesState: Async<CurrentCartItemAvailability[]> = {
  data: null,
  isPending: false,
};

/**
 * Cart Item Availability Reducer implementation
 *
 * @param prevState Previous Store state for cart item availabilities
 * @param action Cart item availability actions
 * @returns Cart item availabilities array in async object of the current store state
 */
export function cartItemAvailabilitiesReducer(
  prevState: Async<CurrentCartItemAvailability[]>,
  action: CartItemAvailablityActions | CartItemActions
): Async<CurrentCartItemAvailability[]> {
  let clonedState: Async<CurrentCartItemAvailability[]>;

  switch (action.type) {
    case CartItemAvailablityActionTypes.GetCartItemAvailablityRequest:
      clonedState = clonedeep(prevState);
      clonedState.error = undefined;
      clonedState.isPending = true;
      return clonedState;
    case CartItemAvailablityActionTypes.GetCartItemAvailablityFailure:
      clonedState = clonedeep(prevState);
      clonedState.error = action.payload.error;
      clonedState.isPending = false;
      return clonedState;
    case CartItemAvailablityActionTypes.GetCartItemAvailablitySuccess:
      clonedState = clonedeep(prevState);
      clonedState.error = undefined;
      clonedState.isPending = false;
      clonedState.data = action.payload.availabilities;
      return clonedState;

    case CartItemActionTypes.ResetCartItemStateAction:
      clonedState = clonedeep(prevState);
      clonedState.data = [];

      return clonedState;
    default:
      return prevState;
  }
}
