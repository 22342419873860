import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpxOAuthService } from '@seepex/ng-oauth';

@Component({
  selector: 'app-oauthcallback',
  template: ``,
})
export class OAuthCallbackComponent implements OnInit {
  constructor(private oAuthService: SpxOAuthService, private router: Router) {}

  public ngOnInit(): void {
    const validToken = this.oAuthService.hasValidAccessToken();

    if (validToken) {
      this.router.navigate(['']);
    }
  }
}
