import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * WebpackTranslateLoader
 *
 * Webpack loading strategy for translation files (results in hashed .js files)
 */
@Injectable()
export class WebpackTranslateLoader implements TranslateLoader {

  /**
   * Returns the translations for a given language
   *
   * @param lang Language string (e.g. 'en')
   * @returns translations for a given language from webpack
   */
  getTranslation(lang: string): Observable<any> {
    const content = import(`../../../assets/locales/${lang}.json`);
    return from(content).pipe(
      map(zoneAwarePromise => zoneAwarePromise.default),
    );
  }
}
