import { ActionReducer, combineReducers } from '@ngrx/store';
import { CartItems } from '../CartItems';
import { cartItemAvailabilitiesReducer, intitialCartItemAvailybilitiesState } from './cart-item-availability.reducer';
import { cartItemPriceReducer } from './cart-item-price.reducer';
import { cartItemsReducer, intitialCartState } from './cart-items.reducer';

/**
 * Cart Items Reducers with initial state
 */
export const cartItemsReducers: ActionReducer<CartItems, any> = combineReducers(
  {
    cartItems: cartItemsReducer,
    cartItemPrices: cartItemPriceReducer,
    cartItemAvailabilities: cartItemAvailabilitiesReducer,
  },
  {
    cartItems: intitialCartState,
    cartItemPrices: [],
    cartItemAvailabilities: intitialCartItemAvailybilitiesState,
  }
);
