import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { NavigationEnd, Router } from '@angular/router';
import { SearchService } from 'app/core/services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  /**
   * Search input control
   */
  public searchControl: FormControl = new FormControl(null, [Validators.minLength(5)]);

  @ViewChild(MatInput, { static: true, read: ElementRef }) input: ElementRef;

  constructor(private searchService: SearchService, private router: Router, private renderer: Renderer2) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Resets the search bar, when a navigation was triggered
        this.searchControl.reset();
        this.input.nativeElement.blur();
      }
    });
  }

  /**
   * Search shop by string
   *
   * @param event (optional) Keyboard event
   */
  public search(event?: KeyboardEvent): void {
    if (this.searchControl.valid && (!event || event.key === 'Enter')) {
      this.searchService.searchAndNavigate(this.searchControl.value?.trim());
    }
  }
}
