import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { Observable } from 'rxjs';
import { IIdentity } from '../models';
import { OAuthHttpService } from '../services';

@Injectable()
export class IAMHttpRepository {
  constructor(private http: OAuthHttpService) {}

  public ensureHasClaim(claim: string, claimValue?: string): Observable<any> {
    let params: HttpParams = new HttpParams();

    if (claimValue) {
      params = params.set('claimValue', claimValue);
    }

    return this.http.get<any>(`${environment.endpoint}/identityaccessmanagement/ensurehasclaim/${claim}`, { params });
  }

  public getIdentity(): Observable<IIdentity> {
    return this.http.get<IIdentity>(`${environment.endpoint}/identityaccessmanagement/identity`);
  }

  public getSalesOrganizationId(): Observable<string> {
    return this.http.get<string>(`${environment.endpoint}/account/salesorg`);
  }
}
