import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PageState } from '../models/page-state';
import { AppStateCollectionService } from './app-state-collection.service';

/**
 * AppStateSelectorService
 *
 * Selects the App State data from the store with defined selectors
 */
@Injectable({
  providedIn: 'root',
})
export class AppStateSelectorService {
  /**
   * @param store Ngrx store instance
   * @param appStateCollectionService App State collection service
   */
  constructor(private store: Store, private appStateCollectionService: AppStateCollectionService) {}

  /**
   * Get the cart items from the store
   *
   * @returns Async object with cart item array
   */
  public getPageState(route: string): Observable<PageState> {
    return this.store.select(this.appStateCollectionService.selectPageState(route));
  }
}
