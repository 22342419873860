import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'app/providers/window';
import { Observable, of, throwError } from 'rxjs';
import { OCIParameters } from '../models';

@Injectable()
export class OCIParametersService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly OCI_PARAMETERS_STORAGE_ITEM: string = 'oci_parameters';

  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Saves the OCI Parameters in the session storage
   *
   * @param params OCI Parameters
   * @returns Complete stream
   */
  public saveOCIParameters(params: OCIParameters): Observable<boolean> {
    if (!params.HookUrl) {
      return throwError('The mandatory paramter HOOK_URL is missing.');
    }

    this.window.sessionStorage.setItem(this.OCI_PARAMETERS_STORAGE_ITEM, JSON.stringify(params));

    return of(true);
  }

  /**
   * Gets the OCI parameters from the session storage and parses them
   *
   * @returns Parsed OCI parameters
   */
  public getOCIParameters(): Observable<OCIParameters> {
    const jsonParams: string = this.window.sessionStorage.getItem(this.OCI_PARAMETERS_STORAGE_ITEM);

    if (!jsonParams) {
      return of(null);
    }

    return of(JSON.parse(jsonParams));
  }
}
