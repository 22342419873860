import { Injectable } from '@angular/core';
import { OAuthHttpService } from 'app/auth';
import { environment } from 'environment';
import { Observable } from 'rxjs';

/**
 * TermsAndConditionRepository
 *
 * This repository is used to get the terms and conditions
 */
@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionRepository {
  constructor(private oAuthHttpService: OAuthHttpService) {}

  getTermsAndCondition(): Observable<Blob> {
    return this.oAuthHttpService.downloadBlob(`${environment.endpoint}/documents/termsandconditionsofsalesanddelivery`);
  }
}
