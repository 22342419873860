import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@seepex/ng-oauth';
import { NoAccessComponent, NoAccessGuard, OAuthCallbackComponent, OCIParametersComponent, SingleSignOnLoginGuard } from './auth';
import { LayoutComponent } from './layout/components/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'noaccess',
        component: NoAccessComponent,
      },
      {
        path: 'cart',
        loadChildren: () => import('./pages/cart-current').then((m) => m.CartCurrentModule),
        canActivate: [NoAccessGuard],
      },
      {
        path: '',
        loadChildren: () => import('./legal').then((m) => m.LegalModule),
        canActivateChild: [NoAccessGuard],
      },
      {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        canActivateChild: [NoAccessGuard],
      },
      {
        path: 'spareparts',
        loadChildren: () => import('./pages/spareparts/spareparts.module').then((m) => m.SparepartsModule),
        canActivateChild: [NoAccessGuard],
      },
      {
        path: 'savedcarts',
        loadChildren: () => import('./pages/saved-carts/saved-carts.module').then((m) => m.SavedCartsModule),
        canActivateChild: [NoAccessGuard],
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactModule),
        canActivateChild: [NoAccessGuard],
      },
      {
        path: 'devices',
        loadChildren: () => import('./pages/device-detail/device-detail.module').then((m) => m.DeviceDetailModule),
        canActivateChild: [NoAccessGuard],
      },
      {
        path: 'checkout',
        loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule),
        canActivateChild: [NoAccessGuard],
      },
      {
        path: 'orders',
        loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersModule),
        canActivateChild: [NoAccessGuard],
      },
      {
        path: 'quotes',
        loadChildren: () => import('./pages/quotes/quotes.module').then((m) => m.QuotesModule),
        canActivateChild: [NoAccessGuard],
      },
    ],
  },
  {
    path: 'fastcheckout',
    loadChildren: () => import('./fastcheckout/fastcheckout.module').then((m) => m.FastCheckoutModule),
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
  },
  {
    path: 'oci',
    component: OCIParametersComponent,
    canActivate: [SingleSignOnLoginGuard],
  },
  {
    path: 'oauth2callback',
    component: OAuthCallbackComponent,
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class SparepartshopRoutingModule {}
