<spx-sidenav>
  <div header>
    <app-header></app-header>
  </div>
  <div branding>{{ 'NAVIGATION.SHOP' | translate | uppercase }}</div>
  <div items>
    <app-navigation></app-navigation>
  </div>
  <div content fxFlex>
    <spx-spinner *ngIf="loading | async" [size]="'SMALL'" [overlay]="true"></spx-spinner>
    <div class="content" fxFlex="100">
      <router-outlet></router-outlet>
    </div>
  </div>
  <spx-menu footer>
    <spx-menu-item [translationKey]="'NAVIGATION.INFORMATION'" [icon]="'info'">
      <spx-menu-item [route]="'imprint'" [translationKey]="'NAVIGATION.IMPRINT'"></spx-menu-item>
      <spx-menu-item [route]="'https://www.irco.com/' + locale + '/terms'" [translationKey]="'NAVIGATION.PRIVACY'"></spx-menu-item>
      <spx-menu-item (click)="showTermsAndCondition()" [translationKey]="'NAVIGATION.TERMS'"></spx-menu-item>
    </spx-menu-item>
  </spx-menu>
</spx-sidenav>
<app-usersnap></app-usersnap>
