import * as clonedeep from 'lodash.clonedeep';
import { PageStateActions, PageStateActionTypes, SetPageStateAction } from '../actions/page-state.actions';
import { PageState } from '../models/page-state';

export function pageStateReducer(prevState: PageState[], action: PageStateActions): PageState[] {
  const clonedState: PageState[] = clonedeep(prevState);

  if (action.type === PageStateActionTypes.SetPageState) {
    return updatePageState(clonedState, action);
  }
  return prevState;
}

function updatePageState(pageStates: PageState[], action: SetPageStateAction): PageState[] {
  const index = pageStates.findIndex((p) => p.route === action.payload.pageState.route);
  if (index >= 0) {
    pageStates[index] = action.payload.pageState;
  } else {
    pageStates.push(action.payload.pageState);
  }

  return pageStates;
}
