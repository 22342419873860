// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  name: 'production',
  authorization: {
    clientId: 'spareparts-online-client',
    scopes: 'spareparts-online-scope openid profile email',
    authorizationUri: 'https://auth3.seepex.com',
    redirectUri: 'https://spareparts.seepex.com/oauth2callback',
  },
  applicationLoginClaimValue: 'SPS',
  endpoint: 'https://spareparts-api.seepex.com/v1',
  supportedLanguages: ['de', 'en', 'fr', 'ja', 'ko', 'th', 'vi'],
  fallbackLanguage: 'en',
};
