import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthHttpService } from 'app/auth';
import { environment } from 'environment';
import { Observable } from 'rxjs';
import { CartItem, CurrentCartItemAvailability, CurrentCartItemPrice } from '../models';

/**
 * CartHttprepository
 *
 * This class contains the Http implementation for the current cart
 */
@Injectable()
export class CarttHttpRepository {
  /**
   * @param http Authenticated HttpClient
   */
  constructor(private http: OAuthHttpService) {}

  /**
   * Get the cart items from the current cart
   *
   * @returns List of cart items
   */
  public getCurrentCartItems(): Observable<Array<CartItem>> {
    return this.http.get<Array<CartItem>>(`${environment.endpoint}/carts/current/items`);
  }

  /**
   * Deletes a cart item from the current cart
   *
   * @param id Cart item id
   * @returns Deletion http response
   */
  public removeItem(id: number): Observable<any> {
    return this.http.delete(`${environment.endpoint}/carts/current/items/${id}`);
  }

  /**
   * Removes all cart items from the current cart
   *
   * @returns Deletion http response
   */
  public removeAllCartItems(): Observable<any> {
    return this.http.delete(`${environment.endpoint}/carts/current/items`);
  }

  /**
   * Changes the quantity of a cart item
   *
   * @param id Cart item id
   * @param quantity New quantity
   * @returns Change http response
   */
  public changeQuantityForItem(id: number, quantity: number): Observable<any> {
    return this.http.put<Observable<any>>(`${environment.endpoint}/carts/current/items/${id}`, { Quantity: quantity });
  }

  /**
   * Get the list of availabilities for the current cart
   *
   * @param salesOrganizationId Sales organization id
   * @returns List of cart item availabilities
   */
  public getAvailabilities(salesOrganizationId: string): Observable<Array<CurrentCartItemAvailability>> {
    let params: HttpParams = new HttpParams();

    if (salesOrganizationId) {
      params = params.set('salesOrganizationId', salesOrganizationId);
    }

    return this.http.get<Array<CurrentCartItemAvailability>>(`${environment.endpoint}/carts/current/items/availability`, { params });
  }

  /**
   * Get the price of the cart item by id
   *
   * @param id Cart item id
   * @param customerNumber Customer number
   * @param salesOrganizationId Sales organization id
   * @returns Price of cart item
   */
  public getPrice(id: number, customerNumber: string, salesOrganizationId: string): Observable<CurrentCartItemPrice> {
    let params: HttpParams = new HttpParams();

    if (salesOrganizationId) {
      params = params.set('salesOrganizationId', salesOrganizationId);
    }

    if (customerNumber) {
      params = params.set('customerNumber', customerNumber);
    }

    return this.http.get<CurrentCartItemPrice>(`${environment.endpoint}/carts/current/items/${id}/price`, { params });
  }

  /**
   * Adds cart items to the current cart
   *
   * @param items Cart Items
   * @returns Success stream
   */
  public addCartItemsToCart(items: Array<CartItem>): Observable<boolean> {
    return this.http.post<boolean>(`${environment.endpoint}/carts/current/items`, items);
  }
}
