import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { WINDOW } from '@seepex/ng-base';

@Component({
  selector: 'app-usersnap',
  templateUrl: './user-snap.component.html',
})
export class UserSnapComponent implements AfterViewInit {
  @Input()
  globalApiKey = 'af717ea0-8416-41f2-ab39-282736025f93';

  @Input()
  userApiKey = '846af8a0-5378-4c78-8a5d-88a09e49a270';

  @ViewChild('script') script: ElementRef;

  constructor(@Inject(WINDOW) private window: Window) {}

  ngAfterViewInit() {
    this.convertToScript();
  }

  convertToScript() {
    (this.window as any).onUsersnapCXLoad = (api) => {
      api.init();
      api.show(this.userApiKey);
    };
    const script = document.createElement('script');
    script.defer = false;
    script.type = 'text/javascript';
    script.src = `https://widget.usersnap.com/global/load/${this.globalApiKey}?onload=onUsersnapCXLoad`;
    const element = this.script.nativeElement;
    const parent = element.parentElement;
    parent.parentElement.replaceChild(script, parent);
  }
}
