import { Component, Inject, LOCALE_ID } from '@angular/core';
import { GoogleAnalyticsService, LoadingService, TermsAndConditionService } from 'app/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  // template variables
  public loading: Observable<boolean>;

  public fileUrl: string;

  constructor(
    private gaService: GoogleAnalyticsService,
    private loadingService: LoadingService,
    @Inject(LOCALE_ID) public locale: string,
    private termsAndConditionService: TermsAndConditionService
  ) {
    // register to loading event
    this.loading = this.loadingService.isLoading;

    // initialize the google analytics functionality
    this.gaService.initialize();

    // get the terms and condition pdf once
    this.termsAndConditionService.getTermsAndCondition().subscribe((test) => {
      this.fileUrl = URL.createObjectURL(test);
    });
  }

  /**
   * Shows the terms and condition pdf in a new tab
   */
  public showTermsAndCondition(): void {
    this.termsAndConditionService.openTermsAndCondtion(this.fileUrl);
  }
}
