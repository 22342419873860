import { Injector, LOCALE_ID, Provider } from '@angular/core';
import { LocalizationConfig, LOCALIZATION_CONFIG } from './localization.config';

/**
 * Factory to get the locale string
 *
 * @param injector Angular injetor
 * @returns language string without locales (e.g. de, en, ...)
 */
export const localeProviderFactory = (injector: Injector): string => {
  const localizationConfig: LocalizationConfig = injector.get(LOCALIZATION_CONFIG);

  let navigatorLanguage: string = window.navigator.language;
  navigatorLanguage = navigatorLanguage.toLowerCase();

  if (navigatorLanguage.length > 2) {
    navigatorLanguage = navigatorLanguage.substring(0, 3).replace(/([^a-z]+)/g, '');
  }

  if (!localizationConfig.locales.includes(navigatorLanguage)) {
    navigatorLanguage = localizationConfig.defaultLocale;
  }

  return navigatorLanguage;
};

/**
 * Provides the locale for the current language
 */
export const localeProvider: Provider = {
  provide: LOCALE_ID,
  useFactory: localeProviderFactory,
  deps: [Injector],
};
