import { Component } from '@angular/core';
import { SpxOAuthService } from '@seepex/ng-oauth';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
})
export class NoAccessComponent {
  constructor(private oAuthService: SpxOAuthService) {}

  /**
   * Logs User out
   */
  public onLogout(): void {
    this.oAuthService.logOut();
  }
}
