import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class OAuthHttpService {
  constructor(private httpClient: HttpClient) {}

  public get<T>(url: string, options: { headers?: HttpHeaders; params?: HttpParams } = {}): Observable<T> {
    return this.httpClient.get<T>(url, options);
  }

  public downloadBlob(url: string, options: { headers?: HttpHeaders; params?: HttpParams } = {}): Observable<Blob> {
    return this.httpClient.get(url, { ...options, responseType: 'blob' });
  }

  public post<T>(url: string, body: any, options: { headers?: HttpHeaders; params?: HttpParams } = {}): Observable<T> {
    return this.httpClient.post<T>(url, body, options);
  }

  public downloadArrayBufferWithPost(url: string, body: any): Observable<HttpResponse<ArrayBuffer>> {
    return this.httpClient.post(url, body, { responseType: 'arraybuffer', observe: 'response' });
  }

  public put<T>(url: string, body: any, options: { headers?: HttpHeaders; params?: HttpParams } = {}): Observable<T> {
    return this.httpClient.put<T>(url, body, options);
  }

  public delete<T>(url: string, options: { headers?: HttpHeaders; params?: HttpParams } = {}): Observable<T> {
    return this.httpClient.delete<T>(url, options);
  }

  public patch<T>(url: string, body: any, options: { headers?: HttpHeaders; params?: HttpParams } = {}): Observable<T> {
    return this.httpClient.patch<T>(url, body, options);
  }

  public head<T>(url: string, options: { headers?: HttpHeaders; params?: HttpParams } = {}): Observable<T> {
    return this.httpClient.head<T>(url, options);
  }

  public options<T>(url: string, options: { headers?: HttpHeaders; params?: HttpParams } = {}): Observable<T> {
    return this.httpClient.options<T>(url, options);
  }
}
