import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BASE_PAGE_SERVICE_PROVIDER } from 'app/shared/modules/base-page/providers/page-page.provider';
import { PageStateActionService } from 'app/store/actions/page-state.action.service';
import { AppStateSelectorService } from 'app/store/selectors/app-state-selector.service';
import { basePageServiceFactory } from './providers/base-page.provider';
import { GoogleAnalyticsService, LoggingService } from './services';

@NgModule({
  imports: [CommonModule],
  providers: [
    GoogleAnalyticsService,
    LoggingService,
    {
      provide: BASE_PAGE_SERVICE_PROVIDER,
      useFactory: basePageServiceFactory,
      deps: [PageStateActionService, AppStateSelectorService, Router],
    },
  ],
})
export class CoreModule {}
