import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TermsAndConditionRepository } from '../repositories/terms-and-condition.repository';

/**
 * TermsAndConditionService
 *
 * This service is used to get the terms and conditions
 */
@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionService {
  constructor(private termsAndConditionRepository: TermsAndConditionRepository) {}

  getTermsAndCondition(): Observable<Blob> {
    return this.termsAndConditionRepository.getTermsAndCondition();
  }

  openTermsAndCondtion(fileUrl: string): void {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
  }
}
