import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Async } from 'app/store';
import { CartItem, CurrentCartItemPrice } from '../../models';
import { CartItems } from '../CartItems';

/**
 * CartItemCollectionService
 *
 * Service which contains all defined selectors for cart items
 */
@Injectable({
  providedIn: 'root',
})
export class CartItemCollectionService {
  // Feature collection selector (must be same name as feature collection name)
  private getCartItemState = createFeatureSelector<CartItems>('cartItems');

  /**
   * Selects the cart items
   */
  public selectCartItems = createSelector(this.getCartItemState, (cartItems: CartItems) => cartItems.cartItems);

  /**
   * Selects the count of the cart items
   */
  public selectCartItemsCount = createSelector(this.getCartItemState, (cartItems: CartItems) => (cartItems.cartItems.data ? cartItems.cartItems.data.length : null));

  /**
   * Selects the cart item prices
   */
  public selectCartPrices = createSelector(this.getCartItemState, (cartItems: CartItems) => cartItems.cartItemPrices);

  /**
   * Selects the cart item availabilities
   */
  public selectCartItemAvailabilities = createSelector(this.getCartItemState, (cartItems: CartItems) => cartItems.cartItemAvailabilities);

  /**
   * Selects the price object of the current cart item
   *
   * @param cartId cart item ID
   * @returns Async object of the current cart item price of undefined when no price was found
   */
  public selectCartItemPrice = (cartId: number) =>
    createSelector(this.selectCartPrices, (cartItemPrices: Async<CurrentCartItemPrice>[]) => {
      if (!cartItemPrices) {
        return undefined;
      }

      const cartItemPrice = cartItemPrices.find((price) => price.data.Id === cartId);

      // find price by cart item ID
      return cartItemPrice ? cartItemPrice : { data: null, isPending: true };
    });

  /**
   * Selects the cart item by material number and serial number
   *
   * @param materialNumber sparepart material number
   * @param position position of cart item
   * @returns cart item stream
   */
  public selectCartItem = (materialNumber: string, position: string, pumpMaterialNumber: string) =>
    createSelector(this.selectCartItems, (cartItems: Async<CartItem[]>) => {
      if (!cartItems) {
        return undefined;
      }

      // find cart item by position
      return cartItems.data?.find((cartItem) => cartItem.MaterialNumber === materialNumber && cartItem.Position === position && cartItem.PumpMaterialNumber === pumpMaterialNumber);
    });
}
