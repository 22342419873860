import { InjectionToken } from '@angular/core';
import { environment } from 'environment';
import { AuthConfig } from '../models/auth.config';

/**
 * Auth Config Injection Token
 */
export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth.config');

/**
 * Default Auth configuration
 */
export const AUTH_CONFIG_DEFAULT: AuthConfig = {
  clientId: environment.authorization.clientId,
  authorizationUri: environment.authorization.authorizationUri,
  redirectUri: environment.authorization.redirectUri,
  responseTypes: 'id_token token',
  scopes: environment.authorization.scopes,
};
