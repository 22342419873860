import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SpxOAuthService } from '@seepex/ng-oauth';
import { LoggingService } from 'app/core';
import { CartItemActionService, CartItemSelectorService } from 'app/features/cart';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cart-status',
  templateUrl: './cart-status.component.html',
  styleUrls: ['./cart-status.component.scss'],
})
export class CartStatusComponent implements OnInit {
  public itemCount$: Observable<number>;
  public quoteOrdering: boolean;

  constructor(
    private router: Router,
    private logging: LoggingService,
    private cartItemSelectorService: CartItemSelectorService,
    private oAuthService: SpxOAuthService,
    private cartItemActionService: CartItemActionService
  ) {}

  public ngOnInit(): void {
    if (this.oAuthService.hasValidAccessToken()) {
      this.cartItemActionService.getCartItems();
    }

    // select the cart items count from the store
    this.itemCount$ = this.cartItemSelectorService.getCartItemsCount();

    // set opacity on cart status, when a quote is ordered
    this.router.events.subscribe((navigationEvent) => {
      if (navigationEvent instanceof NavigationEnd) {
        this.quoteOrdering = new RegExp('^/quotes/.*/order$').test(navigationEvent.url.split(';')[0]);
      }
    });
  }

  public onShoppingCartClick(): void {
    this.logging.sendLoggingEvent('clicked on shopping cart in top bar', 'cart', 'click', 'clicked on shopping cart in top bar');
    this.router.navigate(['cart']);
  }
}
