import { Action } from '@ngrx/store';
import { CurrentCartItemPrice } from '../../models';

/**
 * Cart Item Price Action Types
 */
export enum CartItemPriceActionTypes {
  GetCartItemPriceRequest = '[CartItem] Get Cart Price Request',
  GetCartItemPriceFailure = '[CartItem] Get Cart Price Failure',
  GetCartItemPriceSuccess = '[CartItem] Get Cart Price Success',
}

/**
 * Get cart item price request action definition
 */
export class GetCartItemPriceRequestAction implements Action {
  readonly type = CartItemPriceActionTypes.GetCartItemPriceRequest;

  constructor(readonly payload: { id: number; customerNumber: string; salesOrganizationId: string }) {}
}

/**
 * Get cart item price failure action definition
 */
export class GetCartItemPriceFailureAction implements Action {
  readonly type = CartItemPriceActionTypes.GetCartItemPriceFailure;

  constructor(readonly payload: { id: number; error: any }) {}
}

/**
 * Get cart item price success action definition
 */
export class GetCartItemPriceSuccessAction implements Action {
  readonly type = CartItemPriceActionTypes.GetCartItemPriceSuccess;

  constructor(readonly payload: { id: number; cartItemPrice: CurrentCartItemPrice }) {}
}

/**
 * Cart Item Price Actions
 */
export type CartItemPriceActions = GetCartItemPriceRequestAction | GetCartItemPriceFailureAction | GetCartItemPriceSuccessAction;
