import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environment';
import { Observable } from 'rxjs';
import { LocalizationConfig, LOCALIZATION_CONFIG } from '../providers';

/**
 * TranslationService
 *
 * Translation implementation based on ngx-translate library (https://github.com/ngx-translate/core)
 */
@Injectable()
export class TranslationService {
  /**
   * @param translateService Translate service from ngx-translate library
   * @param locale Current locale
   */
  constructor(private translateService: TranslateService, @Inject(LOCALE_ID) private locale: string, @Inject(LOCALIZATION_CONFIG) private localizationConfig: LocalizationConfig) {}

  /**
   * Initializes the translation logik
   */
  public initialize(): void {
    // set english as default language
    this.translateService.setDefaultLang(this.localizationConfig.defaultLocale);

    // adds the supported languages from the env into the library
    this.translateService.addLangs(environment.supportedLanguages);

    // set the usage language, provided by the Angular LOCALE_ID
    this.translateService.use(this.locale);
  }

  /**
   * Translates a translation key with options to the selected language
   *
   * @param key translation key e.g. 'CONTACT'
   * @param options options to place in the translation
   */
  public translate(key: string | string[], options?: any): Observable<any> {
    return this.translateService.get(key, options);
  }
}
