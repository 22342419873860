import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService, NotificationType } from '@seepex/ng-base';
import { NEVER, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchOrderKey } from '../models/search';
import { SearchHttpRepository } from '../repositories/search.repository.http';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private static SEARCH_TYPE = 'seepex description';

  /**
   * Number type for serial number
   */
  public static NUMBERTYPE_SERIALNUMBER = 'serialnumber';

  /**
   * Number type for article
   */
  public static NUMBERTYPE_ARTICLENUMBER = 'articlenumber';

  /**
   * Number type for order
   */
  public static NUMBERTYPE_ORDERNUMBER = 'ordernumber';

  /**
   * Number type for quote
   */
  public static NUMBERTYPE_QUOTENUMBER = 'quotenumber';

  constructor(
    private searchRepositoryHttp: SearchHttpRepository,
    private loadingService: LoadingService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  /**
   * Gets the number type by search from the server
   *
   * @param searchString search string
   * @returns Numbertype as string (e.g. ordernumber)
   */
  public search(search: string): Observable<string> {
    if (!search) {
      return NEVER;
    }

    const searchString = search.replace(/\*/g, '%').trim();
    return this.searchRepositoryHttp.search(searchString, SearchService.SEARCH_TYPE).pipe(map((searchResult) => searchResult?.NumberType ?? null));
  }

  /**
   * Gets the order key for a order
   *
   * @param orderNumber Order number
   * @returns Order key object
   */
  public getOrderHirstoryKeys(search: string): Observable<SearchOrderKey> {
    return this.searchRepositoryHttp.getOrderHirstoryKeys(search);
  }

  public searchAndNavigate(search: string): void {
    this.loadingService.startLoading();
    this.search(search).subscribe(
      (searchString: string) => {
        // route by number type of search response
        switch (searchString) {
          case SearchService.NUMBERTYPE_ARTICLENUMBER:
            this.loadingService.stopLoading();
            this.router.navigate(['spareparts', { articlenumber: search }]);
            break;
          case SearchService.NUMBERTYPE_SERIALNUMBER:
            this.loadingService.stopLoading();
            this.router.navigate(['devices', search]);
            break;
          case SearchService.NUMBERTYPE_QUOTENUMBER:
            this.loadingService.stopLoading();
            this.router.navigate(['quotes', search]);
            break;
          case SearchService.NUMBERTYPE_ORDERNUMBER:
            this.getOrderHirstoryKeys(search).subscribe(
              (response: any) => {
                this.loadingService.stopLoading();
                return this.router.navigate(['orders', response.Id, response.OrderType]);
              },
              () => {
                this.loadingService.stopLoading();
              }
            );
            break;

          default:
            this.loadingService.stopLoading();
            this.notificationService.showNotification({ message: 'SEARCH.NO_RESULT', type: NotificationType.Error });
            break;
        }
      },
      (error: Error) => {
        this.loadingService.stopLoading();
        this.notificationService.showNotification({ message: 'SEARCH.ERROR', type: NotificationType.Error });
      }
    );
  }
}
