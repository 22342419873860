import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NotificationModule } from '@seepex/ng-base';
import * as Sentry from '@sentry/angular';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from 'environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { SparepartshopRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OAuthModule } from './auth';
import { CoreModule } from './core/core.module';
import { MaintenanceInterceptor } from './interceptors/maintenance.interceptor';
import { LayoutModule } from './layout/layout.module';
import { WINDOW, windowFactory } from './providers/window';
import { appStateReducers } from './store/reducers/reducers';
import { LOCALIZATION_CONFIG, TranslationModule, WebpackTranslateLoader } from './translation';

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    LayoutModule,
    CommonModule,
    SparepartshopRoutingModule,
    NotificationModule,
    OAuthModule,
    MatNativeDateModule,
    TranslationModule,
    RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
        deps: [LOCALIZATION_CONFIG],
      },
    }),
    BrowserAnimationsModule,
    StoreModule.forRoot({ appState: appStateReducers }),
    EffectsModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    GoogleTagManagerModule.forRoot({
      id: 'GTM-N42V2H5',
    }),
  ],
  providers: [
    {
      provide: WINDOW,
      useFactory: windowFactory,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Lcmn8QlAAAAANb-3tACYRn-d8tFZha-cyQyYy8O',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
