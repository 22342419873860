import { ActionReducer, combineReducers } from '@ngrx/store';
import { AppState } from '../AppState';
import { pageStateReducer } from './page-state-reducer';

export const appStateReducers: ActionReducer<AppState, any> = combineReducers(
  {
    pageStates: pageStateReducer,
  },
  {
    pageStates: [],
  }
);
