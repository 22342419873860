import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoggingService } from './logging.service';

/**
 * Function definition for Google Analytics
 */
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

/**
 * Google Analytics Service
 *
 * This service controls automated events, which should be send to Google Analytics
 */
@Injectable()
export class GoogleAnalyticsService {
  /**
   * @param router Angular router instance
   * @param logging Logging service to send logs to the server
   */
  constructor(private router: Router, private logging: LoggingService) {}

  /**
   * Initializes the Google Analytics Events
   */
  public initialize(): void {
    this.initializePageEvents();
  }

  /**
   * Initializes the Page View Events
   */
  private initializePageEvents(): void {
    // After each Navigation End Event, we send the navigated event
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('?')[0];
        this.logging.sendLoggingEvent('navigated to page: ' + url, 'navigate', '', '');
        gtag('config', 'UA-32273320-8', {
          'page-path': url,
        });
      }
    });
  }
}
