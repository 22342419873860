import { Action } from '@ngrx/store';
import { PageState } from '../models/page-state';

/**
 * Page State Actions
 */
export enum PageStateActionTypes {
  SetPageState = '[PageState] Set Page State',
}

/**
 * Set Page State Action definition
 */
export class SetPageStateAction implements Action {
  readonly type = PageStateActionTypes.SetPageState;

  constructor(readonly payload: { pageState: PageState }) {}
}

/**
 * Page State Actions
 */
export type PageStateActions = SetPageStateAction;
