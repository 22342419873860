import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CartService } from '../../services';
import { CartItemAvailablityActionTypes, GetCartItemAvailablityRequestAction } from '../actions/cart-item-availability.actions';

/**
 * CartItemAvailybilityEffect
 *
 * Ngrx Effects for the availabilities of cart items
 */
@Injectable({
  providedIn: 'root',
})
export class CartItemAvailybilityEffect {
  /**
   * @param actions$ Ngrx Actions
   * @param cartCurrentService Cart current service
   */
  constructor(private actions$: Actions, private cartCurrentService: CartService) {}

  /**
   * Get cart item availabilities
   */
  cartItemAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCartItemAvailablityRequestAction>(CartItemAvailablityActionTypes.GetCartItemAvailablityRequest),
      mergeMap((action) =>
        this.cartCurrentService.getAvailabilities(action.payload.salesOrganizationId).pipe(
          map((availabilities) => ({ type: CartItemAvailablityActionTypes.GetCartItemAvailablitySuccess, payload: { availabilities } })),
          catchError((error) => of({ type: CartItemAvailablityActionTypes.GetCartItemAvailablityFailure, payload: { error } }))
        )
      )
    )
  );
}
